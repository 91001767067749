

function TopNav() {
    return (
        <div class="topnav">
            <a class="active" href="#home">Software By Whaley</a>
            <a href="#about">About</a>
            <a href="#Employement">Career</a>
            <a href="#email">Contact me</a>
        </div>
    );
}

export default TopNav;
